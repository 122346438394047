import Vue from 'vue';

export default {
    
    updateContrato(parametros){
        return Vue.axios.post("contratos/update", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateCarpetaContrato(parametros){
        return Vue.axios.post("contratos/folder", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addContrato(parametros){
        return Vue.axios.post("contratos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addImage(parametros){
        return Vue.axios.post("contratos/imagen", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateArchivo(parametros){
        return Vue.axios.post("contratos/almacenar", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    renovarContrato(parametros){
        return Vue.axios.post("contratos/renovar", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    renovarContratoMasivo(parametros){
        return Vue.axios.post("contratos/renovar-masivo", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    find(parametros) {
        return Vue.axios.get("contratos-formatos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    regenerarContrato(parametros){
        return Vue.axios.post("contratos/regenerar", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    regenerarContratoMasivo(parametros){
        return Vue.axios.post("contratos/regenerar-masivo", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    deleteImage(id) {
        return Vue.axios.delete("contratos/imagen/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("contratos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteDocumento(parametros){
        return Vue.axios.post("contratos/updateFile", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDocumento(parametros){
        return Vue.axios.post("contratos/updateFile", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteFormat(id) {
        return Vue.axios.delete("contratos-formatos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },


    //campos adicionales

    findCampos(parametros) {
        return Vue.axios.get("contratos-adicionales/campos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getCampos(id) {
        return Vue.axios.get("contratos-adicionales/campos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateCampos(parametros) {
        return Vue.axios.put("contratos-adicionales/campos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteCampos(id) {
        return Vue.axios.delete("contratos-adicionales/campos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storeCampos(parametros) {
        return Vue.axios.post("contratos-adicionales/campos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    findDatos(parametros) {
        return Vue.axios.get("contratos-adicionales/datos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDatos(id) {
        return Vue.axios.get("contratos-adicionales/datos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDatos(parametros) {
        return Vue.axios.put("contratos-adicionales/datos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteDatos(id) {
        return Vue.axios.delete("contratos-adicionales/datos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storeDatos(parametros) {
        return Vue.axios.post("contratos-adicionales/datos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    //Estatus de contratos
    findEstatus(parametros) {
        return Vue.axios.get("contratos/catalogo-estatus/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    //Configuración Notificaciones
    findConfiguracions(parametros) {
        return Vue.axios.get("contratos/configuraciones-notificaciones/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getConfiguracion(id) {
        return Vue.axios.get("contratos/configuraciones-notificaciones/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateConfiguracion(parametros) {
        return Vue.axios.put("contratos/configuraciones-notificaciones", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteConfiguracion(id) {
        return Vue.axios.delete("contratos/configuraciones-notificaciones/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storeConfiguracion(parametros) {
        return Vue.axios.post("contratos/configuraciones-notificaciones", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    //otros documentos
    generarDocumentoMasivo(parametros){
        return Vue.axios.post("contratos/generar-documentos-masivo", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    regenerarDocumentosMasivo(parametros){
        return Vue.axios.post("contratos/regenerar-documentos-masivo", parametros ).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    regenerarDocumento(parametros){
        return Vue.axios.post("contratos/regenerar-documentos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleadosDocumentos(parametros){
        return Vue.axios.get("contratos/empleados-otros-documentos",{params:parametros}).then((response)=> Promise.resolve(response.data)).catch((error)=> Promise.reject(error))
    }

}